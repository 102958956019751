// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["U_0YmwyQ9", "wbAThgMpq"];

const serializationHash = "framer-EyjBE"

const variantClassNames = {U_0YmwyQ9: "framer-v-19u81t6", wbAThgMpq: "framer-v-j59y6e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "U_0YmwyQ9", "Variant 2": "wbAThgMpq"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "U_0YmwyQ9"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "U_0YmwyQ9", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1xoxqqw = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("wbAThgMpq")
})

const onTap1m9211q = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("U_0YmwyQ9")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19u81t6", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"U_0YmwyQ9"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1xoxqqw} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-6f65be5b-d434-40c7-9377-eff99acd05cd, rgb(143, 143, 143))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, rotate: 0, ...style}} variants={{wbAThgMpq: {rotate: 180}}} {...addPropertyOverrides({wbAThgMpq: {"data-framer-name": "Variant 2", onTap: onTap1m9211q}}, baseVariant, gestureVariant)}><motion.div className={"framer-1eqaspv"} data-border layoutDependency={layoutDependency} layoutId={"mqyUNolvY"} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-8ed0f524-5690-4ee5-ae11-cc9b60d389b9, rgb(0, 0, 0))"}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-EyjBE[data-border=\"true\"]::after, .framer-EyjBE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EyjBE.framer-808cdd, .framer-EyjBE .framer-808cdd { display: block; }", ".framer-EyjBE.framer-19u81t6 { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 40px; will-change: var(--framer-will-change-override, transform); }", ".framer-EyjBE .framer-1eqaspv { flex: none; height: 100%; left: 0px; overflow: hidden; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 50%; }", ".framer-EyjBE.framer-v-j59y6e.framer-19u81t6 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wbAThgMpq":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerewsxzjaUj: React.ComponentType<Props> = withCSS(Component, css, "framer-EyjBE") as typeof Component;
export default FramerewsxzjaUj;

FramerewsxzjaUj.displayName = "Light/Dark Switch";

FramerewsxzjaUj.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerewsxzjaUj, {variant: {options: ["U_0YmwyQ9", "wbAThgMpq"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerewsxzjaUj, [])